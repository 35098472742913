import React, { Component } from 'react';
import './sectionFive.css';
import ModalContent from '../../ModalContent/ModalContent';
import ServiceHomeComponents from '../serviceHomeComponents/ServiceHomeComponents';
import Object3DModular from './object3dModular'

export class sectionFive extends Component {
    render() {
        const services = [
            {
               title: "Contenido",
                text: 'Impulsa tu presencia online con contenido de alta calidad que capte la atención de tu audiencia.¡Haz clic aquí y comencemos a crear contenido increíble!',
                modalContent: (
                    <ModalContent
                        title="Contenido"
                        description="Descripción del contenido de alta calidad."
                        sceneUrl='https://prod.spline.design/p0JZRIBfuRgdHFvA/scene.splinecode'
                    />
                )
            },
            {
               title: "Branding",
                text: 'Transforma la identidad de tu marca y conéctate con tu audiencia de manera única. ¡Haz clic aquí y empieza a construir una marca inolvidable!',
                modalContent: (
                    <ModalContent
                        title="Branding"
                        description="Descripción sobre la transformación de la marca."
                        sceneUrl='https://prod.spline.design/Lt6XCRkXVj1QvQJd/scene.splinecode'
                    />
                )
            },
            {
              title:"DPA",
                text: 'Asegura la protección y análisis de tus datos con nuestros expertos en DPA. ¡Haz clic aquí y mejora la seguridad y eficiencia de tu empresa!',
                modalContent: (
                    <ModalContent
                        title="DPA"
                        description="Descripción sobre protección y análisis de datos."
                        sceneUrl='https://prod.spline.design/KPfczsuGz4jMZVYU/scene.splinecode'
                    />
                )
            },
            {
                title:"Administración",
                text: 'Optimiza tus procesos administrativos con nuestras soluciones eficientes y personalizadas. ¡Haz clic aquí y mejora la gestión de tu negocio hoy mismo!',
                modalContent: (
                    <ModalContent
                        title="Administración"
                        description="Descripción sobre optimización de procesos administrativos."
                        sceneUrl='https://prod.spline.design/iGEQRoZcIFS0hRUf/scene.splinecode'
                    />
                )
            },
            {
                title: "Tecnología",
                text: 'Innova y crece con nuestras soluciones tecnológicas a medida. ¡Haz clic aquí y descubre cómo la tecnología puede transformar tu negocio!',
                modalContent: (
                    <ModalContent
                        title="Tecnología"
                        description="Descripción sobre soluciones tecnológicas a medida."
                         sceneUrl='https://prod.spline.design/OgRLoIPeyOK-8SRo/scene.splinecode'
                    />
                )
            },
        ];

        return (
            <div className='contentFiveContainer'>
                <div className='contentFive'>
                    {services.map((service, index) => (
                        <ServiceHomeComponents
                            key={index}
                            title={service.title}
                            text={service.text}
                            isTextAbove={index % 2 === 0}
                            modalContent={service.modalContent}
                        />
                    ))}
                </div>
            </div>
        );
    }
}

export default sectionFive;