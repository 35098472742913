import React, { Component } from 'react';
import './sectionOne.css';
import Object3D from './Object3D';
import logoCTA from '../../../images/sm_blanco puro y naranja plus.png';
import 'animate.css';

export class SectionOne extends Component {
    state = {
        scrollY: window.scrollY, // Capturar la posición inicial del scroll al cargar
        mouse: { x: 0, y: 0 },
    };

    handleScroll = () => {
        this.setState({ scrollY: window.scrollY });
    };

    handleMouseMove = (event) => {
        this.setState({ mouse: { x: event.clientX, y: event.clientY } });
    };

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('mousemove', this.handleMouseMove);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('mousemove', this.handleMouseMove);
    }

    render() {
        return (
            <div className='contentTotal'>
                <Object3D scrollY={this.state.scrollY} mouse={this.state.mouse} />
                <div className='sectionOneMain'>
                    <div className='sectionOneContainer'>
                        <div className='sectionOneContent'>
                            <img className='mainBannerImage animate__fadeIn' src={logoCTA} alt="Logo CTA" />
                            <p>
                                Somos un grupo de cosmonautas, que decidieron navegar a través de los cúmulos del marketing, con estrategias innovadoras para hacer crecer tu marca.
                            </p>
                            <h2>BIENVENIDO A LA ACCIÓN</h2>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SectionOne;
