import React, { useState, useEffect, useRef } from 'react';

const ImageSequencer = ({ folderPath, totalFrames, frameRate = 1 }) => {
  const [scrollY, setScrollY] = useState(0);
  const [frameIndex, setFrameIndex] = useState(0);
  const [isComplete, setIsComplete] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const containerRef = useRef(null);
  const [containerTop, setContainerTop] = useState(0);
  const [containerBottom, setContainerBottom] = useState(0);
  const [responsiveStyles, setResponsiveStyles] = useState({ width: '100%', height: '100vh' });
  const [containerStyle, setContainerStyle] = useState({ height: '300vh', position: 'relative' });

  // Función para pre-cargar las imágenes
  const preloadImages = () => {
    const promises = [];
    for (let i = 0; i < totalFrames; i++) {
      const img = new Image();
      img.src = getImagePath(i);
      promises.push(
        new Promise((resolve) => {
          img.onload = resolve;
        })
      );
    }
    return Promise.all(promises);
  };

  useEffect(() => {
    preloadImages().then(() => {
      setImagesLoaded(true); // Marca como cargadas todas las imágenes
    });
  }, []);

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      const top = rect.top + window.scrollY;
      const bottom = top + containerRef.current.offsetHeight;
      setContainerTop(top);
      setContainerBottom(bottom);
    }
  }, [containerRef.current]);

  useEffect(() => {
    const startScroll = containerTop;
    const endScroll = containerBottom;

    if (scrollY >= startScroll && scrollY <= endScroll) {
      const scrollRange = endScroll - startScroll;
      const newIndex = Math.min(totalFrames - 1, Math.floor((scrollY - startScroll) / (scrollRange / totalFrames)));
      setFrameIndex(newIndex);
      setIsComplete(false);
    } else if (scrollY > endScroll) {
      setFrameIndex(totalFrames - 1);
      setIsComplete(true);
    } else {
      setFrameIndex(0);
      setIsComplete(false);
    }
  }, [scrollY, totalFrames, containerTop, containerBottom]);

  const getImagePath = (index) => {
    const paddedIndex = String(index + 1).padStart(3, '0'); // 001, 002, ...
    return `${folderPath}ezgif-frame-0${paddedIndex}.jpg`;
  };

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setIsMobile(width <= 768);

      if (width > 1024) {
        setResponsiveStyles({ width: '100%', height: '100vh' });
      } else if (width <= 1024 && width > 768) {
        setResponsiveStyles({ width: '100%', height: '100vh' });
      } else if (width <= 768 && width > 480) {
        setResponsiveStyles({ width: '100%', height: '100vh' });
      } else {
        setResponsiveStyles({ width: '100%', height: '100vh' });
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isSticky = scrollY >= containerTop && scrollY <= containerBottom + window.innerHeight;

  const imagePosition = isSticky
    ? { position: 'sticky', top: '0px' }
    : isMobile && scrollY > containerBottom - window.innerHeight
    ? { position: 'absolute', top: `${containerBottom - containerTop + window.innerHeight}px`, width: '100%' }
    : { position: 'relative', top: '0' };

  return (
    <>
      {!imagesLoaded && (
        <div className="loader">
          {/* Puedes reemplazar este div con cualquier animación de carga */}
          Cargando...
        </div>
      )}
      {imagesLoaded && (
        <div ref={containerRef} className={`image-sequencer ${isComplete ? 'complete' : ''}`} style={containerStyle}>
          <img
            src={getImagePath(frameIndex)}
            style={{
              ...imagePosition,
              ...responsiveStyles, // Aplicar los estilos responsivos
              maxHeight: '100%',
            }}
            alt={`Frame ${frameIndex}`}
          />
        </div>
      )}
    </>
  );
};

export default ImageSequencer;
