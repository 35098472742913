import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logoCTA from '../../images/sm_blanco puro y naranja plus.png';
import { FaBars } from 'react-icons/fa';
import './Navbar.css';

export class Navbar extends Component {
  render() {
    return (
      <div className='topContentContainer'>
        {/* <button className="hamburger-icon">
          <FaBars />
        </button> */}
         <Link to="/"> <img className='mainBannerImg' src={logoCTA} alt="Logo CTA" /></Link>
        {/* <img className='mainBannerImg' src={logoCTA} alt="Logo CTA" /> */}
        <nav className='nav-links'>
          <Link to="/">Home</Link>
          <Link to="/services">Services</Link>
         
          {/* <Link to="/projects">Projects</Link> */}
          <Link className='contactButton' to="/contact">Contact</Link>
        </nav>
      </div>
    );
  }
}

export default Navbar;
