import React, { useRef, useState, useEffect } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { useGLTF } from '@react-three/drei';

// Hook personalizado para ajustar la escala y la posición en Y responsiva
const useResponsiveTransform = () => {
    const [transform, setTransform] = useState({ scale: [0.2, 0.2, 0.2], positionY: 0, scrollFactor: 250 });

    useEffect(() => {
        const updateTransform = () => {
            const width = window.innerWidth;
            if (width > 1024) {
                setTransform({ scale: [0.2, 0.2, 0.2], positionY: 0, scrollFactor: 250 });
            } else if (width <= 1024 && width > 768) {
                setTransform({ scale: [0.15, 0.15, 0.15], positionY: 0.8, scrollFactor: 250 });
            } else if (width <= 768 && width > 480) {
                setTransform({ scale: [0.1, 0.1, 0.1], positionY: -2, scrollFactor: 150 });
            } else {
                setTransform({ scale: [0.15, 0.15, 0.15], positionY: -0.2, scrollFactor: 100 });
            }
        };

        window.addEventListener('resize', updateTransform);
        updateTransform();

        return () => window.removeEventListener('resize', updateTransform);
    }, []);

    return transform;
};

const RotatingModel = ({ scrollY,  isMounted }) => {
    const ref = useRef();
    const { scene } = useGLTF('/models/rocket.gltf');
    const { scale, positionY, scrollFactor } = useResponsiveTransform();

    useEffect(() => {
        if (isMounted && ref.current) {
            ref.current.scale.set(...scale);
            ref.current.position.y = positionY - scrollY / scrollFactor;
        }
    }, [isMounted, scale, positionY, scrollFactor, scrollY]);

    useFrame(() => {
        if (isMounted && ref.current) {
            ref.current.position.y = positionY - scrollY / scrollFactor;
        }
    });

    return isMounted ? <primitive object={scene} ref={ref} /> : null;
};

const Object3D = ({ scrollY, mouse }) => {
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        // Retardar la renderización hasta que el componente esté listo
        setIsMounted(true);
    }, []);

    return (
        <Canvas className="canvas animate__animated animate__zoomIn">
            <directionalLight intensity={8} position={[10, 0, -5]} />
            <directionalLight intensity={8} position={[0, -10, -5]} />
            <directionalLight intensity={4} position={[10, 50, -5]} />
            <RotatingModel scrollY={scrollY} mouse={mouse} isMounted={isMounted} />
        </Canvas>
    );
};

export default Object3D;
